import CryptoJS from 'crypto-js';

export function unreverseCode(str: string) {
  str = str + 'GXQoaQjxgLKuaPChtuJD';
  return CryptoJS.MD5(str).toString().toLowerCase();
}

export function encode(str: string) {
  return CryptoJS.MD5(str).toString().toLowerCase();
}
