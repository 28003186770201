import React from 'react';
import { Switch, Route, Redirect } from 'dva/router';

const RouteWithProps: any = ({ path, exact, strict, render, location, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    render={props => render({ ...props, ...rest })}
  />
);

function getCompatProps(props) {
  const compatProps: any = {};

  if (props.match && props.match.params && !props.params) {
    compatProps.params = props.match.params;
  }
  return compatProps;
}

export default function renderRoutes(routes, extraProps = {}, switchProps = {}) {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => {
        const RouteRoute = RouteWithProps;
        return (
          <RouteRoute
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props => {
              const childRoutes = renderRoutes(
                route.children,
                {},
                {
                  location: props.location
                }
              );
              if (route.component) {
                const compatProps = getCompatProps({
                  ...props,
                  ...extraProps
                });

                const newProps = {
                  ...props,
                  ...extraProps,
                  ...compatProps,
                  args: { route }
                };

                if (route.menuCode) {
                  // const hasAuth = window.kraken.invoke('checkAuthOnPageEnter')(route.menuCode);
                  // if (!hasAuth) {
                  //   return false;
                  // }
                }
                return (
                  <route.component {...newProps} breadcrumbRoutes={routes} route={route}>
                    {childRoutes}
                  </route.component>
                );
              } else if (route.redirect) {
                return <Redirect {...route.redirect} />;
              } else {
                return childRoutes;
              }
            }}
          />
        );
      })}
    </Switch>
  ) : null;
}
