import { IDict } from '@/dataModel/dict/interface';

let dict: IDict;

export function getDict(): IDict {
  return dict || ({} as IDict);
}

export function setDict(newDict: IDict) {
  dict = Object.entries(newDict ?? {}).reduce(
    (prev, [key, dictList]) => ({ ...prev, [key]: dictList ?? [] }),
    {} as any
  );
}

/**
 * 从字典的枚举数组中取出某几个组成新数组
 */
export function pickDictEnum<T extends Array<{ id: string; name: string }>>(enumData: T, pickIds: string[]) {
  return (enumData || []).filter(item => pickIds.includes(item?.id)); // 防止字典不规范返回值
}

/**
 * 从字典的枚举数组中根据枚举名 id find 某一项
 */
export function findDictEnum<T extends Array<{ id: string; name: string }>>(enumData: T, enumName: string): T[0] {
  return (enumData || []).find(({ id }) => id === enumName); // 防止字典不规范返回值
}
