import { IPriceTagPrint } from '@/dataModel/business/priceTag/interface';
import { degressEnum } from '@/pages/StoreBackend/Equipment/TagPrint/components/priceTagPrintPaper/index';
import config from '@/config';
import { PageStyleSetEnum } from '@/pages/HeadquartersBackend/Store/TagAdd/consts';
import { ISettingBillInfo } from '@/dataModel/system/settingBill/interface';
import { ICheckboxStore } from '@/pages/HeadquartersBackend/Goods/StoreGoods/components/SaleGoods';

const bosId = window.kraken.getCache('keyData').bosId;
enum TabKeys {
  Pending = 'pending',
  Processed = 'processed'
}
enum TabsListEnum {
  Wait = 'wait',
  History = 'history'
}
enum StoreLossType {
  /**
   *  待审核单据
   */
  'Audit' = 'await',
  /**
   *   历史审核单据
   */
  'History' = 'history'
}
export interface ILocalStorage {
  /** 只是举例 */
  ExampleKey: string;
  /** 表格 每页数量，key 为表格类型 */
  PageSizeSetting: Record<string, number>;
  /**价签模板设置*/
  TagPrintCurrentSettings?: PreviewData;
  TagPrintTagSettings?: PreviewData;
  /**当前选择的价签模板*/
  TagPrintActiveIndex?: number;
  /**商品重构 线上信息 */
  OnlineInfo?: boolean;
  /**商品重构 是否是le版本 */
  IsLe?: boolean;
  priceJin?: boolean;
  storePrint?: string; //总部价签打印-按门店打印,
  isExpandTreeNode?: boolean;
  // 暂定any.涉及单据类型  单据事件
  storeMarket?: any;
  /** 点击推荐有奖时间 */
  RecommendedTime:string
}

interface PreviewData {
  sizeWidth: number;
  sizeHeight: number;
  isLock: boolean;
  degress: degressEnum;
  line: number;
  count: number;
  left: number;
  locTop: number;
  space: number;
  tagLocUpDown: number;
  pageSetting: PageStyleSetEnum;
}
interface IUserTableSetting {
  /** 标识名，目前为页面 url */
  businessType: string;
  /** 存储的 pageSize */
  pageSize: number;
}
interface Chain {
  uuid: string;
  qty: number;
}

export interface ISessionStorage {
  /** 只是举例 */
  ExampleKey: string;
  /** 上一次的 storeId 标识，用于切换门店入口页面的高亮 */
  PrevStoreId?: string;
  /**配送方案商品uuid */
  DistGoodsUuids?: string[];
  /** 用户表格设置 */
  UserTableSetting: IUserTableSetting[];
  /**打印部分当前选择要打印table内商品存在uuid商品 */
  TagPrintRows?: IPriceTagPrint[];
  TagPrintWaitPList?: IPriceTagPrint[];
  /**打印部分当前选择要打删选出的 PrintRows*/
  TagPrintInfoArray?: [string, number][];
  /**打印部分当前选中行的数量修改*/
  TagPrintChain?: Chain[];
  /**打印部分当前选中行*/
  TagPrintRowsList?: IPriceTagPrint[];
  /**线上商品库搜索条件 */
  OnlineSearchFilter?: string;
  /**价格方案商品uuid */
  PlanGoodsUuids?: string[];
  /** 缓存pathname用于queryform查询 */
  PathNameUrl?: string;
  /** 缓存用于queryform查询提交不固定故用any */
  queryParameter?: any;
  /** 缓存用于当前页面、 */
  pageSetting?: {
    page: number;
    pageSize: number;
  };

  /** 仓库加工分割方式 */
  wrhProcessTabIndex?: string;
  /** 全局单据配置 */
  billInfoSetting: ISettingBillInfo;
  /** 总部店务-门店商品库选择的门店 */
  choosedStoreFormStoreGoods: ICheckboxStore[];
  /** 商品调价tab */
  adjustPrice?: TabKeys;
  /** 门店退货申请tab */
  storeReturnList?: TabsListEnum;
  /** 门店报损tab */
  storeLoss?: StoreLossType;
  queryPage?: {
    page: number;
    pageSize: number;
  };
  wrhAllocTabKey?: string;
  typeEq?: string;
  statusEq?: any[];
  typeEqBack?: string;
  statusEqBack?: any[];
  storeTransferTab?: string;
  storeStoreTransferTab?: string;
  financeContractTab?: string;
  wrhCheckInTab?: string;
  returnNotify?: string;
  ticketTemplateTab?: { tab?: string; uuid?:string};
}

/** 拼接子应用标识和租户id，防冲突 */
function getSystemKey(key: string) {
  return `${config.system}__${bosId}__${key}`;
}

export function getLocalStorage<T extends ILocalStorage, K extends keyof T>(key: K): T[K] {
  const storage = window.localStorage.getItem(getSystemKey(key as string));
  return storage ? JSON.parse(storage).data : undefined;
}

export function setLocalStorage<T extends ILocalStorage, K extends keyof T>(key: K, value: T[K]) {
  const res = JSON.stringify({ data: value });
  window.localStorage.setItem(getSystemKey(key as string), res);
}

export function removeLocalStorage(key: keyof ILocalStorage) {
  setLocalStorage(key, undefined);
}

export function getSessionStorage<T extends ISessionStorage, K extends keyof T>(key: K): T[K] {
  const storage = window.sessionStorage.getItem(getSystemKey(key as string));
  return storage ? JSON.parse(storage).data : undefined;
}

export function setSessionStorage<T extends ISessionStorage, K extends keyof T>(key: K, value: T[K]) {
  const res = JSON.stringify({ data: value });
  window.sessionStorage.setItem(getSystemKey(key as string), res);
}

export function removeSessionStorage(key: keyof ISessionStorage) {
  setSessionStorage(key, undefined);
}
