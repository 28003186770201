import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import dynamic from 'dva/dynamic';
import qs from 'qs';
import config, { numericalRange } from '@/config';
import moment from 'moment';
import isPlainObject from 'lodash/isPlainObject';
import isNil from 'lodash/isNil';
import { ConfigsEnum, GoodsTypeEnum, MerchantPackTypeEnum } from '@/dataModel/dict/enum';
import NP from 'number-precision';
import { getDict } from './dict';
import { isArray } from 'lodash';
/** 生成基本路由信息 */
export function createRouteConfig(name: string, routePath: string, children = []) {
  const route = {
    name,
    path: routePath,
    menuCode: routePathToMenuCode(routePath),
    exact: true
  };

  if (!!children?.length) {
    return { ...route, exact: false, children };
  }

  return route;
}

/**
 * 路由路径转为 menuCode 格式
 * @param pathname 页面路径，总部后台或门店后台开始(含)
 * @returns string 例：xxx.xx.xxx
 */
export function routePathToMenuCode(pathname: string) {
  const toCamelCodes = text => text.replace(/\-(\w)/g, (_, letter) => letter.toUpperCase());
  return `app${pathname.split('/').map(toCamelCodes).join('.')}`;
}

/**
 * 生成动态组件
 * @param {*} app
 * @param {*} models
 * @param {*} component
 */
export const dynamicWrapper = (app: any, models: any, component: any) => {
  return dynamic({
    app,
    models: () => models,
    component
  } as any);
};

/**
 * 拼接完整的路由路径
 * @param {*} router
 */
 export function jumpRouter(router: any,app?:string): string {
  const cachePidStore = window.kraken.getCache('keyData');
  if (cachePidStore) {
    return `/bos/products/${app?app:config.system}/${cachePidStore.bosId}/${window.kraken.getCache('bosIdKey').key}${router}`;
  }

  return '';
}

/**
 *  get请求url 参数
 * @param {*} path
 * @param {*} query
 */
export function getQueryPath(path = '', query = {}) {
  const search = qs.stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/**
 *  获取 search 参数
 * @param search
 */
export function getUrlSearch(search) {
  return qs.parse(search, { ignoreQueryPrefix: true });
}

/**
 *  递归函数
 * @param {*} nodeList
 */
export function getPlainNode(nodeList) {
  const newList = nodeList;
  newList.forEach(_ => nodeChild(_.children, _.path));
  return newList;
}
/**
 * 递归遍历路由函数 o2o#app.store.items2.orderManage
 */
function nodeChild(nodeList, parentPath) {
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    if (item.menuCode) {
      item.menuCode = `${config.systemId}#${item.menuCode}`;
    }
    if (item.children) {
      nodeChild(item.children, item.path);
    }
  });
  return nodeList;
}

/**
 * 获取子集路由
 */
const getSubMenu = item => {
  if (item.children && item.children.some(child => child.name)) {
    return {
      ...item,
      children: filterMenuData(item.children)
    };
  }
  return item;
};

/**
 * 过滤路由数据
 */
const filterMenuData = menuData => {
  if (!menuData) {
    return [];
  }
  return menuData
    .filter(item => item.name)
    .map(item => getSubMenu(item))
    .filter(item => item);
};
/**
 * 获取面包屑映射
 * @param {Object} menuData 菜单配置
 */
const getBreadcrumbNameMap = menuData => {
  const routerMap = {};
  const flattenMenuData = data => {
    data.forEach(menuItem => {
      if (menuItem.children) {
        flattenMenuData(menuItem.children);
      }
      routerMap[menuItem.path] = menuItem;
    });
  };
  flattenMenuData(menuData);
  return routerMap;
};

const memoizeOneGetBreadcrumbNameMap = memoizeOne(getBreadcrumbNameMap, isEqual);
/**
 * 面包屑生成函数
 * @param {*} url
 * @param {*} name
 */
export const createBreadcrumbNameMap = newRoutes => {
  return memoizeOneGetBreadcrumbNameMap(newRoutes);
};

/**
 * 判断对象是否为 Promise
 */
export const isPromise = (obj: any): obj is Promise<any> => {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
};

/**
 * 从一个对象中选择部分 key 组成另一个对象（浅拷贝）
 * @param obj 原始对象
 * @param fields 需要被选中的 key
 * @returns pick 后的对象
 */
export const pick = <T extends { [key: string]: any }, U extends keyof T>(
  obj: T,
  fields: U[] | string[]
): Pick<T, U> => {
  const afterObj: any = {};

  if (!fields?.length || !obj) return afterObj;

  fields.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      afterObj[key as any] = obj[key];
    }
  });

  return afterObj as T;
};

/**
 * 从一个对象中过滤部分 key（浅拷贝）
 * @param obj 原始对象
 * @param fields 需要被过滤的 key
 * @returns 过滤后的对象
 */
export const omit = <T extends { [key: string]: any }, U extends keyof T>(obj: T, fields: U[]): Omit<T, U> => {
  const afterObj: T = { ...obj };

  if (!fields?.length || !obj) return afterObj;

  fields.forEach(key => {
    if (afterObj.hasOwnProperty(key)) {
      delete afterObj[key as string];
    }
  });

  return afterObj;
};

/**
 * 若对象内所有 key 都为 undefeated 或 空字符串，返回 undefeated，否则返回原对象
 */
export const emptyObjToUndefined = <T>(obj?: T): any => {
  if (!obj) return obj;

  const isAllUndefined = Object.values(obj ?? {}).every(value => {
    return value === undefined || value === '';
  });

  return isAllUndefined ? undefined : obj;
};

/**
 * 清除对象中的空字段（undefined/null/''）
 */
export const clearObjEmptyFields = <T extends Record<string, any>>(obj: T) => {
  if (!obj) return obj;
  return Object.entries(obj).reduce((prev, [key, value]) => {
    const pass = value !== '' && value !== null && value !== undefined;
    return pass ? { ...prev, [key]: value } : prev;
  }, {} as T);
};

/**
 * 清除对象中的空字符串对应的属性（后端会取空字符串进行校验）
 */
export const clearObjEmptyString = <T extends Record<string, any>>(obj: T) => {
  if (!obj) return obj;
  return Object.entries(obj).reduce((prev, [key, value]) => {
    const pass = value !== '';
    return pass ? { ...prev, [key]: value } : prev;
  }, {} as T);
};

/**
 * 判断是否是整数
 * @param x
 * @returns
 */
export const isInteger = (x: number) => {
  return (x | 0) === x;
};
/**
 * 今天（24H）-今天 15:30
 * 昨天（24H）-昨天 15:30
 * 一周内（24H）-周一 15:30
 * 更早（24H - 03/02 15:30
 * 跨年（24H）- 2020/03/02 15:30
 * @param time YYYY-MM-DD HH:mm
 */
export const formatDisplayTime = (time: string) => {
  const weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  const momentObj = moment(time, 'YYYY/MM/DD HH:mm');
  const mMoment = moment();
  const cYear = mMoment.year();
  const cMonth = mMoment.month();
  const year = momentObj.year();
  const month = momentObj.month();
  const date = momentObj.date();
  const hour = momentObj.hour();
  const minute = momentObj.minute();
  const diffDate = mMoment.diff(momentObj, 'day');
  let timeStr = '';
  if (year < cYear) {
    timeStr = `${year}/${month + 1}/${date} ${hour}:${minute}`;
  } else if (month < cMonth && diffDate >= 7) {
    timeStr = `${month + 1}/${date} ${hour}:${minute}`;
  } else if (!diffDate) {
    timeStr = `今天 ${hour}:${minute}`;
  } else if (diffDate === 1) {
    timeStr = `昨天 ${hour}:${minute}`;
  } else if (diffDate < 7) {
    timeStr = `${weeks[momentObj.day()]} ${hour}:${minute}`;
  } else {
    timeStr = time;
  }
  return timeStr;
};

/*
 * 解析URL 返回对象 {url: '', params: {}}
 * url 为不带参数的地址。
 * params: 为参数对象
 * */
export const parseUrl = (url: string): any => {
  if (!url) return {};
  const parseList = url.split('?');
  if (parseList.length == 1) {
    return {};
  }
  if (parseList.length == 2) {
    const temp = {};
    const params = parseList[1];
    params.split('&').forEach(item => {
      const [key, val] = item.split('=');
      const value: string | boolean = val ? decodeURI(val) : true;
      // 转码 无值赋值true
      if (temp.hasOwnProperty(key)) {
        //   已有属性转为数组
        temp[key] = [].concat(temp[key], value);
      } else {
        temp[key] = value;
      }
    });
    return temp;
  }
};

/**
 * 对象每一个 value 的 undefined / null 替换成 ''
 *
 * @example patchEmptyObjectDfs({ a: undefined, c: { a: undefined } }) => {a: '', c: {a: ''}}
 * @param {*} obj
 */
export function patchEmptyObjectDfs(obj: any) {
  return Object.keys(obj).reduce((memo, key) => {
    if (isPlainObject(obj[key])) {
      memo[key] = patchEmptyObjectDfs(obj[key]);
      return memo;
    }

    // Array<Object | null> / Array<string|number>
    if (obj[key] instanceof Array) {
      memo[key] = obj[key].map(item =>
        typeof item === 'object' && item !== null ? patchEmptyObjectDfs(item) : item || ''
      );
      return memo;
    }

    memo[key] = isNil(obj[key]) ? '' : obj[key];
    return memo;
  }, {});
}

/**
 * sleep
 *
 * @example (async () => await sleep(1000))()
 * @param delay
 */
export function sleep(delay = 100): Promise<null> {
  return new Promise(resolve => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve(null);
    }, delay);
  });
}

/**
 * 生成唯一标识符
 */
export function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}
/**
 * 生成唯一标识符 仅商品重构使用
 */
export function basicSkuUuid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
}
/**
 * 格式化数字千分位分割
 * @param num 原数字
 * @param toFixedNumber 保留几位小数，默认2位
 * @returns string 12,345.01
 */
export function formatNumber(num?: number | string, toFixedNumber = 2) {
  if (!num) return (0).toFixed(toFixedNumber);

  const [intString, float] = Number(num).toFixed(toFixedNumber).split('.');
  const int = intString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return toFixedNumber > 0 ? `${int}.${float}` : int;
}

/**
 * 四舍五入保留数字
 * @param num 原数字
 * @param toFixedNumber 保留几位小数，默认2位 ------- 废弃
 * @returns Number 12.235 ===>  12.24
 */
export function formatRoundNumberNew(num?: number | string, toFixedNumber = 2) {
  if (!num) return (0).toFixed(toFixedNumber);
  const roundNumber = '0.' + Array(toFixedNumber + 4).join('0') + '1';
  return (Number(num) + Number(roundNumber)).toFixed(toFixedNumber);
}

/**
 * 保留小数点几位数, 自动补零, 四舍五入
 * @param num: 数值
 * @param digit: 小数点后位数
 * @returns Number 12.235 ===>  12.24
 */
export function formatRoundNumber(num: number | string, digit = 2) {
  if (!num) return (0).toFixed(digit);
  const numNew = parseFloat(String(num));
  const numAim = (Math.round((numNew + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(digit);
  return Number(numAim);
}

/**
 * 格式化后端返回的日期时间
 * @param dateString 后端返回的标准时间字符串
 * @param format moment format 参数，默认 YYYY/MM/DD HH:mm
 * @returns string
 */
export function formatDateString(dateString: string, format = 'YYYY/MM/DD HH:mm') {
  if (!dateString) return '';
  return moment(dateString).format(format);
}

/**
 * 下载
 * @param options 配置项
 */
export function download(options: { url: string; downloadName: string }) {
  return fetch(options.url, { method: 'GET' })
    .then(res => res.blob())
    .then(res => {
      const suffix = options.url?.match(/\.\w+$/)?.[0] ?? '';
      const url = window.URL.createObjectURL(res);
      const dom = document.createElement('a');
      dom.download = `${options.downloadName}${suffix}`;
      dom.href = url;
      dom.click();
    });
}

/**
 * 动态加载单个js文件
 * @param path->url script的地址 path->id唯一标识
 * @param callback
 * @returns
 */
export function loadScript(path: { url: string; id: string }) {
  return new Promise((resolve, reject) => {
    try {
      const oldScript = document.getElementById(path.id);
      if (oldScript) {
        if (oldScript.getAttribute(path.id)) {
          resolve(path);
          return;
        } else {
          document.body.removeChild(oldScript);
        }
      }
      const script: HTMLScriptElement = document.createElement('script');
      script.id = path.id;
      script.src = path.url;
      script.onload = function (e) {
        script.setAttribute(path.id, 'finished');
        resolve(path);
      };
      document.body.appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
}

export function loadLink(path: { url: string; id: string }) {
  return new Promise((resolve, reject) => {
    try {
      const oldScript = document.getElementById(path.id);
      if (oldScript) {
        if (oldScript.getAttribute(path.id)) {
          resolve(path);
          return;
        } else {
          document.body.removeChild(oldScript);
        }
      }
      const linkTag: HTMLLinkElement = document.createElement('link');
      linkTag.id = path.id;
      linkTag.href = path.url;
      // linkTag.onload = function (e) {
      linkTag.setAttribute(path.id, 'finished');
      linkTag.setAttribute('rel', 'stylesheet');
      linkTag.setAttribute('media', 'all');
      linkTag.setAttribute('type', 'text/css');
      resolve(path);
      //};
      const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
      head.appendChild(linkTag);
      //document.body.appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
}

export function formatGoodsUnit(
  {
    goodsType,
    goodsQpcStr,
    goodsMunit,
    goodsWeight,
    goodsWeightMunit
  }: {
    goodsType: { id: GoodsTypeEnum; name: string };
    goodsQpcStr?: string;
    goodsBasicMunit?: string;
    goodsMunit?: string;
    goodsWeight?: number;
    goodsWeightMunit?: string;
    goodsName?: string;
    uuid?: string;
    [props: string]: any;
  },
  price?: any
) {
  switch (goodsType?.id) {
    case GoodsTypeEnum.Bulk: {
      const formatGoodsWeight = (goodsWeightMunit: string, goodsWeight: number) =>
        goodsWeightMunit === goodsMunit && goodsWeight === null ? '' : goodsWeight;

      return price || price === 0
        ? `${price}${price ? '/' : ''}${goodsMunit ?? ''}`
        : goodsWeightMunit === goodsMunit
        ? `${goodsMunit ?? ''}`
        : `${formatGoodsWeight(goodsWeightMunit, goodsWeight) ?? ''}${goodsWeightMunit ?? ''}${
            goodsWeightMunit ? '/' : ''
          }${goodsMunit ?? ''}`;
    }
    case GoodsTypeEnum.Single: {
      return price || price === 0
        ? `${price}${price ? '/' : ''}${goodsMunit ?? ''}`
        : `${goodsQpcStr ?? 1}/${goodsMunit ?? ''}`;
    }
    case GoodsTypeEnum.Level:
    case GoodsTypeEnum.Multi:
    case GoodsTypeEnum.Combine: {
      return price || price === 0 ? `${price}${price ? '/' : ''}${goodsMunit ?? ''}` : goodsMunit;
    }
    default:
      return '';
  }
}
export function NewFormatGoodsUnit(
  {
    goodsType,
    qpcStr,
    munit,
    weight,
    weightMunit
  }: {
    goodsType: { id: GoodsTypeEnum; name: string };
    qpcStr?: string;
    basicSkuMunit?: string;
    munit?: string;
    goodsWeight?: number;
    weightMunit?: string;
    name?: string;
    uuid?: string;
    [props: string]: any;
  },
  price?: any
) {
  switch (goodsType?.id) {
    case GoodsTypeEnum.Bulk: {
      const formatGoodsWeight = (weightMunit: string, goodsWeight: number) =>
        weightMunit === munit && goodsWeight === null ? '' : goodsWeight;

      return price || price === 0
        ? `${price}${price ? '/' : ''}{munit ?? ''}`
        : weightMunit === munit
        ? `${munit ?? ''}`
        : `${formatGoodsWeight(weightMunit, weight) ?? ''}${weightMunit ?? ''}${weightMunit ? '/' : ''}${munit ?? ''}`;
    }
    case GoodsTypeEnum.Single: {
      return price || price === 0 ? `${price}${price ? '/' : ''}${munit ?? ''}` : `${qpcStr ?? 1}/${munit ?? ''}`;
    }
    case GoodsTypeEnum.Level:
    case GoodsTypeEnum.Multi:
    case GoodsTypeEnum.Combine: {
      return price || price === 0 ? `${price}${price ? '/' : ''}${munit ?? ''}` : munit;
    }
    default:
      return '';
  }
}

export function qtyStrToQty({ qtyStr, qpc }: { qtyStr: string; qpc: number }): { qtyStr: string; qty: number } {
  let qty = 0;
  if (!qtyStr) {
    return {
      qty,
      qtyStr: '0'
    };
  }

  let mQtyStr = qtyStr.toString();

  if (mQtyStr.includes('+')) {
    //n(整数)+m（整数或小数）
    const qtyStrArr = mQtyStr.split('+');
    qty = NP.plus(NP.times(qtyStrArr[0], qpc), qtyStrArr[1]);
  } else if (!mQtyStr.includes('+') && mQtyStr.includes('.')) {
    //散称小数
    qty = NP.times(mQtyStr, qpc);
    //利用qty再反推qtyStr为n+m
    mQtyStr = qtyToQtyStr({ qty, qpc }).qtyStr;
  } else {
    qty = NP.times(mQtyStr, qpc);
  }

  return {
    qty,
    qtyStr: mQtyStr
  };
}

export function qtyToQtyStr({ qty, qpc }: { qty: number; qpc: number }): {
  qty: number;
  qtyStr: string;
} {
  if (qpc === 1) {
    return {
      qty,
      qtyStr: qty + ''
    };
  }
  let qtyStr = '';
  let over = qty % qpc;

  if (String(over).includes('.')) {
    over = parseFloat(over.toFixed(3));
  }
  // qpc会出现浮点，如qpc=0.2  1.3时
  if (qpc === over) {
    over = 0;
  }
  const num = NP.divide(qty, qpc) || '0';
  qtyStr = `${parseInt(num.toString())}`;
  if (over) {
    const mNum = parseInt(num.toString());
    qtyStr = `${mNum}+${over}`;
  }
  return {
    qty,
    qtyStr
  };
}

/**
 * A页面跳转B页面
 * 在B页面判断是否是A页面跳转过来的
 * @param fromUrl B页面取url上的参数
 * @param targetUrl A页面路由 比如 storeBackend.xx.xx.xx
 * @returns
 */
export function fromUrlEquals(fromUrl: string, targetUrl: string) {
  return decodeURIComponent(fromUrl) === targetUrl;
}

/**
 * 获取数据列表里目标字段的总计值
 * @param data
 * @param totalKeys
 * @returns
 */
export const getDataSourceTargetTotal = <T>(data: T[], totalKeys: string[]) =>
  data.length
    ? Object.keys(data[0]).reduce((acc, curKey) => {
        if (totalKeys.includes(curKey)) {
          acc[curKey] = data.reduce((accTotal, curTotal) => NP.plus(accTotal, curTotal[curKey] ?? 0), 0);
        }
        return acc;
      }, {} as Partial<T>)
    : totalKeys.reduce((acc, cur) => {
        acc[cur] = 0;
        return acc;
      }, {} as Partial<T>);

// 数字转中文大写数字
export function convertCurrency(money) {
  //汉字的数字
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  //基本单位
  const cnIntRadice = ['', '拾', '佰', '仟'];
  //对应整数部分扩展单位
  const cnIntUnits = ['', '万', '亿', '兆'];
  //对应小数部分单位
  const cnDecUnits = ['角', '分', '毫', '厘'];
  //整数金额时后面跟的字符
  const cnInteger = '整';
  //整型完以后的单位
  const cnIntLast = '元';
  //最大处理的数字
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  const maxNum = 999999999999999.9999;
  //金额整数部分
  let integerNum;
  //金额小数部分
  let decimalNum;
  //输出的中文金额字符串
  let chineseStr = '';
  //分离金额后用的数组，预定义
  let parts;
  if (money == '') {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return '';
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf('.') == -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    const IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i++) {
      const n = integerNum.substr(i, 1);
      const p = IntLen - i - 1;
      const q = p / 4;
      const m = p % 4;
      if (n == '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != '') {
    const decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      const n = decimalNum.substr(i, 1);
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
}

export function formatGoodsSpec(value: any, hasBracket = true) {
  if (!value) return '';
  const arr = [];
  let specStr = value;
  if (value && value.includes('{')) {
    const valueObj = JSON.parse(value);
    for (const item in valueObj) {
      arr.push(valueObj[item]);
    }
  }
  if (arr.length && arr.length > 1) {
    specStr = arr.join('/');
  } else if (arr.length) {
    specStr = arr[0];
  }
  return hasBracket ? ` (${specStr})` : specStr;
}

export function formatGoodsSpecNew(value: any, hasBracket = true) {
  if (!value) return '';
  const arr = [];
  let specStr = value;
  if (value && value.includes('{')) {
    const valueObj = JSON.parse(value);
    for (const item in valueObj) {
      arr.push(valueObj[item]);
    }
  } else if (value && isArray(value)) {
    if (value.length >= 1) {
      specStr = value.join('/');
    } else {
      specStr = value[0];
    }
  }

  if (arr.length && arr.length > 1) {
    specStr = arr.join('/');
  } else if (arr.length) {
    specStr = arr[0];
  }
  return hasBracket ? ` ${specStr}` : specStr;
}
export function formatGoodsSpecNoCurve(value: any) {
  const str = formatGoodsSpec(value);
  if (!str) return str;
  const strArr = Array.from(str);
  strArr.pop();
  strArr.shift();
  return strArr.join('');
}

export function getMerchantPackType(): MerchantPackTypeEnum {
  if (!getDict().configs) return MerchantPackTypeEnum.Super;
  //return MerchantPackTypeEnum.Fresh;
  const target = getDict().configs.find(({ id }) => id === ConfigsEnum.PackType);
  return (target?.name as any) ?? MerchantPackTypeEnum.Super;
}

/**
 * 十六进制的颜色值转rgba
 * @param sHex 十六进制的颜色值
 * @param alpha
 * @returns
 */
export function colorRgba(sHex: string, alpha = 1) {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  let sColor = sHex.toLowerCase();
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#';
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    const sColorChange = [];
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
    }
    return 'rgba(' + sColorChange.join(',') + ',' + alpha + ')';
  } else {
    return sColor;
  }
}

/**
 * 回车换行
 * @param tdClassNames
 */
export function changeLine(tdClassNames: string[]) {
  function getElementViewTop(element) {
    let actualTop = element.offsetTop;
    let current = element.offsetParent;
    while (current !== null) {
      actualTop += current.offsetTop;
      current = current.offsetParent;
      if (current.className === 'saas-main-content') break;
    }
    const mainContentEleSt = document.querySelector('.saas-main-content').scrollTop;
    return actualTop - mainContentEleSt;
  }

  const node = document.documentElement;
  const list = tdClassNames.map(item => {
    const nodeList: HTMLInputElement[] = Array.from(document.querySelectorAll(`.${item} input`));
    return nodeList;
  });
  function lineFeed(e) {
    const key = e.code;
    list.forEach(arr => {
      arr.forEach((item, idx) => {
        if (item === e.target) {
          let num = idx;
          if (key === 'NumpadEnter' || key === 'Enter') {
            num = idx + 1;
            if (num >= arr.length) {
              num = 0;
            }
            arr[num].focus();
            arr[num].select();
            const mainContentEle = document.querySelector('.saas-main-content');
            const footerEleH = document.querySelector('#footerComponent').clientHeight;

            const mainContentEleST = mainContentEle.scrollTop;
            const mainContentEleCH = mainContentEle.clientHeight;
            const trueClientHeight = mainContentEleCH - footerEleH;

            const nextOffsetTop = getElementViewTop(arr[num]);
            if (nextOffsetTop > trueClientHeight - arr[num].clientHeight) {
              mainContentEle.scrollTop = mainContentEleST + footerEleH + arr[num].clientHeight;
            }
          }
        }
      });
    });
  }

  node.addEventListener('keydown', lineFeed, false);

  return () => {
    node.removeEventListener('keydown', lineFeed);
  };
}
/**
 * 回车换行
 * @param tdClassNames
 */
export function changeLineNew(tdClassNames: string[]) {
  function getElementViewTop(element) {
    let actualTop = element.offsetTop;
    let current = element.offsetParent;
    while (current !== null) {
      actualTop += current.offsetTop;
      current = current.offsetParent;
      if (current.className === 'saas-main-content') break;
    }
    const mainContentEleSt = document.querySelector('.saas-main-content').scrollTop;
    return actualTop - mainContentEleSt;
  }

  const node = document.documentElement;
  const list = tdClassNames.map(item => {
    const nodeList: HTMLInputElement[] = Array.from(document.querySelectorAll(`.${item} input`));
    return nodeList;
  });
  function lineFeed(e) {
    const key = e.code;
    list.forEach(arr => {
      arr.forEach((item, idx) => {
        if (item === e.target) {
          let num = idx;
          if (key === 'NumpadEnter' || key === 'Enter' || key === 'ArrowDown') {
            const event = e || window.event;
            event.stopPropagation();
            num = idx + 1;
            if (num >= arr.length) {
              num = 0;
            }
            setTimeout(() => {
              arr[num]?.focus();
              arr[num]?.select();
            }, 50);
            const mainContentEle = document.querySelector('.saas-main-content');
            const footerEleH = document.querySelector('#footerComponent').clientHeight;

            const mainContentEleST = mainContentEle.scrollTop;
            const mainContentEleCH = mainContentEle.clientHeight;
            const trueClientHeight = mainContentEleCH - footerEleH;

            const nextOffsetTop = getElementViewTop(arr[num]);
            if (nextOffsetTop > trueClientHeight - arr[num].clientHeight) {
              mainContentEle.scrollTop = mainContentEleST + footerEleH + arr[num].clientHeight;
            }
          }
          // 键盘UP键   ArrowUp
          if (key === 'ArrowUp') {
            const event = e || window.event;
            event.stopPropagation();
            num = idx - 1;
            if (num >= arr.length) {
              num = 0;
            }
            setTimeout(() => {
              arr[num]?.focus();
              arr[num]?.select();
            }, 50);
            const mainContentEle = document.querySelector('.saas-main-content');
            const footerEleH = document.querySelector('#footerComponent').clientHeight;

            const mainContentEleST = mainContentEle.scrollTop;
            const mainContentEleCH = mainContentEle.clientHeight;
            const trueClientHeight = mainContentEleCH - footerEleH;

            const nextOffsetTop = getElementViewTop(arr[num]);
            if (nextOffsetTop > trueClientHeight - arr[num].clientHeight) {
              mainContentEle.scrollTop = mainContentEleST + footerEleH + arr[num].clientHeight;
            }
          }
        }
      });
    });
  }

  node.addEventListener('keydown', lineFeed, false);

  return () => {
    node.removeEventListener('keydown', lineFeed);
  };
}
/**
 * 全键盘操作
 * @param tdClassNames 根据不同的td class
 */
export function fullKeyboardAction(tdClassNames: string[]) {
  function getElementViewTop(element) {
    let actualTop = element.offsetTop;
    let current = element.offsetParent;
    while (current !== null) {
      actualTop += current.offsetTop;
      current = current.offsetParent;
      if (current.className === 'saas-main-content') break;
    }
    const mainContentEleSt = document.querySelector('.saas-main-content').scrollTop;
    return actualTop - mainContentEleSt;
  }

  const node = document.documentElement;
  const list = tdClassNames
    .map(item => {
      const nodeList: HTMLInputElement[] = Array.from(document.querySelectorAll(`.${item} input`));
      return nodeList;
    })
    .filter(item => item.length);
  const searchShortcut: HTMLInputElement = document.querySelector('.cx-search-shortcut input');
  function lineFeed(e) {
    const key = e.code;
    const keyCode = e.keyCode;
    list.forEach((arr, index) => {
      arr.forEach((item, idx) => {
        if (item === e.target) {
          let num = idx;
          // 键盘down键 ArrowDown
          if (keyCode === 40) {
            num = idx + 1;
            if (num >= arr.length) {
              //num = 0;
              const event = e || window.event;
              event.stopImmediatePropagation();
              searchShortcut.focus();
              return;
            } else {
              if (arr[num].classList.contains('cx-calendar-picker-input')) {
                const event = e || window.event;
                event.stopImmediatePropagation();
                setTimeout(() => {
                  arr[num].focus();
                  arr[num].select();
                }, 50);
              } else {
                setTimeout(() => {
                  arr[num].focus();
                  arr[num].select();
                }, 50);
              }
            }

            const mainContentEle = document.querySelector('.saas-main-content');
            const footerEleH = document.querySelector('#footerComponent').clientHeight;
            const mainContentEleST = mainContentEle.scrollTop;
            const mainContentEleCH = mainContentEle.clientHeight;
            const trueClientHeight = mainContentEleCH - footerEleH;
            const nextOffsetTop = getElementViewTop(arr[num]);
            if (nextOffsetTop > trueClientHeight - arr[num].clientHeight) {
              mainContentEle.scrollTop = mainContentEleST + footerEleH + arr[num].clientHeight;
            }
          }
          let curEnterIndex = index;
          // 键盘enter键
          if (keyCode === 13) {
            curEnterIndex = index + 1;
            if (curEnterIndex >= list.length) {
              num = idx + 1;
              curEnterIndex = list.length - 1;
              if (num >= arr.length) {
                const event = e || window.event;
                event.stopImmediatePropagation();
                searchShortcut.focus();
                return;
              }
            }
            if (list[curEnterIndex][num]?.classList?.contains('cx-calendar-picker-input')) {
              setTimeout(() => {
                list[curEnterIndex][num].focus();
                list[curEnterIndex][num].click();
              }, 50);
            }
            setTimeout(() => {
              list[curEnterIndex][num].focus();
              list[curEnterIndex][num].select();
            }, 50);
          }
          // 键盘UP键   ArrowUp
          if (keyCode === 38) {
            num = idx - 1;
            if (num >= arr.length) {
              num = 0;
            }
            setTimeout(() => {
              arr[num].focus();
              arr[num].select();
            }, 50);
            const mainContentEle = document.querySelector('.saas-main-content');
            const footerEleH = document.querySelector('#footerComponent').clientHeight;

            const mainContentEleST = mainContentEle.scrollTop;
            const mainContentEleCH = mainContentEle.clientHeight;
            const trueClientHeight = mainContentEleCH - footerEleH;

            const nextOffsetTop = getElementViewTop(arr[num]);
            if (nextOffsetTop > trueClientHeight - arr[num].clientHeight) {
              mainContentEle.scrollTop = mainContentEleST + footerEleH + arr[num].clientHeight;
            }
          }
          let curIndex = index;
          // 向右   ArrowRight
          if (keyCode === 39) {
            curIndex = index + 1;
            if (curIndex >= list.length) {
              curIndex = 0;
            }
            if (list[curIndex][num]?.classList?.contains('cx-calendar-picker-input')) {
              setTimeout(() => {
                list[curIndex][num].focus();
                list[curIndex][num].click();
              }, 50);
            }
            setTimeout(() => {
              list[curIndex][num].focus();
              list[curIndex][num].select();
            }, 50);
          }
          //向左  ArrowLeft
          if (keyCode === 37) {
            curIndex = index - 1;
            if (curIndex < 0) {
              curIndex = list.length;
            }
            setTimeout(() => {
              list[curIndex][num].focus();
              list[curIndex][num].select();
            }, 50);
          }
        }
      });
    });
  }

  node.addEventListener('keydown', lineFeed, false);

  return () => {
    node.removeEventListener('keydown', lineFeed);
  };
}
/**
 * 格式化财务供应商银行卡号
 * @example 6225 **** **** *** 606
 */
export function formatBankAccountNo(bankAccountNo: string | null, isSecret = false) {
  if (!bankAccountNo) return '-';
  if (!isSecret) return bankAccountNo.match(/.{1,4}/g).join(' ');

  const bankAccountNoLength = bankAccountNo.length;
  const secretString = '*'
    .repeat(bankAccountNoLength - 7)
    .match(/.{1,4}/g)
    .join(' ');
  const subBankAccountNo = bankAccountNo.replace(
    bankAccountNo.substring(4, bankAccountNoLength - 3),
    ` ${secretString} `
  );
  return subBankAccountNo;
}

export function isMacOS() {
  const userAgent = navigator.userAgent.toLocaleLowerCase();
  if (/mac os/.test(userAgent)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 加载统计脚本
 * @example hdcxUid = "_loginid"
 * hdcxTid = "_tenantid"
 */
export function loadTracker(path: { uid: string; id: string; tid: string }) {
  return new Promise((resolve, reject) => {
    try {
      const oldTrackerDiv = document.getElementById(path.id);
      if (oldTrackerDiv) {
        if (oldTrackerDiv.getAttribute(path.id)) {
          resolve(path);
          return;
        } else {
          document.body.removeChild(oldTrackerDiv);
        }
      }
      const div: HTMLDivElement = document.createElement('div');
      div.id = path.id;
      div.setAttribute(path.id, 'finished');
      div.setAttribute('hdcxUid', path.uid);
      div.setAttribute('hdcxTid', path.tid);
      resolve(path);
      document.body.appendChild(div);
    } catch (error) {
      reject(error);
    }
  });
}
export const stringProcess = {
  format: function () {
    if (arguments.length === 0) {
      return null;
    }
    // eslint-disable-next-line prefer-rest-params
    let str = arguments[0];
    for (let i = 1; i < arguments.length; i++) {
      const re = new RegExp('\\{' + (i - 1) + '\\}', 'gm');
      // eslint-disable-next-line prefer-rest-params
      str = str.replace(re, arguments[i]);
    }
    return str;
  },
  htmlEncode: function (s) {
    const div = document.createElement('div');
    div.appendChild(document.createTextNode(s));
    return div.innerHTML;
  },
  htmlDecode: function (s) {
    const div = document.createElement('div');
    div.innerHTML = s;
    return div.innerText || div.textContent;
  }
};
export function addHtmlElement(
  top?: any,
  left?: any,
  width?: any,
  height?: any,
  text?: any,
  align?: any,
  encode?: any
) {
  if (!align) {
    align = 'left';
  }
  if (encode) {
    text = stringProcess.htmlEncode(text);
  }
  const div =
    '<div style=\\"background-color:white;\\">' +
    '<div style=\\"text-align:{0};font-size:9pt;background-color:white;word-wrap:break-word;word-break:break-all;border:solid 0 white;white-space: pre;\\">{1}</div>' +
    '</div>';
  text = stringProcess.format(div, align, text);

  if (typeof top === 'string') {
    top = '"' + top + '"';
  }
  if (typeof left === 'string') {
    left = '"' + left + '"';
  }
  if (typeof width === 'string') {
    width = '"' + width + '"';
  }
  if (typeof height === 'string') {
    height = '"' + height + '"';
  }
  if (typeof text === 'string') {
    text = '"' + text + '"';
  }
  return stringProcess.format('lodop.ADD_PRINT_HTM({0}, {1}, {2}, {3}, {4});', top, left, width, height, text);
}
/**
 *  pos58小票模板
 */

export function payPrint(htmlList: string[]) {
  let tpl = '';
  let tpl1 = '';
  let tplBarcode = '';
  const newHtmlList = htmlList.map(item => item.replace(new RegExp(' ', 'gm'), '&ensp;'));

  let barcodeHeight = 0;
  for (let i = 0; i < newHtmlList.length; i++) {
    barcodeHeight += 12;
    if (newHtmlList[i].includes('${')) {
      const barCode = htmlList[i].split('${')[1].split('}')[0].split('_')[1];
      tplBarcode += `lodop.ADD_PRINT_BARCODE(${barcodeHeight}, 10, '20%', 50,"128Auto","${barCode}");`;
      tplBarcode += 'lodop.NEWPAGEA();';
      tpl1 +=
        '<div style=\\"background-color:white;height:65;\\">' +
        '<div style=\\"width:48mm; font-size:8pt;background-color:white;word-break:break-all;word-wrap:break-word;border:solid 0 white;white-space: pre;\\">' +
        '</div>' +
        '</div>';
    } else {
      tpl1 +=
        '<div style=\\"background-color:white;\\">' +
        '<div style=\\"width:48mm;font-size:8pt;background-color:white;word-break:break-all;word-wrap:break-word;border:solid 0 white;white-space: pre;\\">' +
        newHtmlList[i] +
        '</div>' +
        '</div>';
    }
  }
  tpl += addHtmlElement(0, 0, '116mm', '290mm', tpl1, 'left');
  return tpl + tplBarcode;
}

/**查询单天时 将时间参数处理为后端需要的两个入参 */
export function handleDealDateParams(_date: any) {
  if (!_date) return {};
  if (!_date._isAMomentObject) return {};
  return {
    createStartTime: _date ? _date.format('YYYY-MM-DD 00:00:00') : '',
    createEndTime: _date ? _date?.format('YYYY-MM-DD 00:00:00') : ''
  };
}

/**
 * 判断进货数量 库存数量是否超过最大值限制
 * @param value 进货数量 库存数量
 * @param goodsType 商品类型
 * @returns boolean true超过 false未超过
 */
export function judgeQtyOverMax(value: number, goodsType: GoodsTypeEnum) {
  // 散称
  const bulkLimitation = goodsType === GoodsTypeEnum.Bulk && Number(value ?? 0) > numericalRange.qty.bulkRange.max;
  // 非散称
  const otherLimitation =
    goodsType !== GoodsTypeEnum.Bulk && Number(value ?? 0) > numericalRange.qty.exceptBulkRange.max;
  if (bulkLimitation || otherLimitation) {
    return true;
  }
  return false;
}
