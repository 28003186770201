import { routePathToMenuCode } from './utils';
import { AccessEnum } from '@/dataModel/access/enum';
import { hadHeadingAccess } from './headingAccess';

/**
 * 创建模块权限映射
 * 后端的按钮区分标识为：app 开头(菜单为 heading#app 开头)
 *
 * @param moduleId 模块前缀
 * @param access 除[查询]以外的权限数组
 */
export function createAccessMap(moduleId: string, access?: AccessEnum[]) {
  const list = [AccessEnum.Query, ...(access ?? [])].reduce((prev, string) => {
    return [...prev, string];
  }, []);

  const accessMap: Record<AccessEnum, string> = list.reduce((prev, accessId) => {
    return { ...prev, [accessId]: `wosheading#${routePathToMenuCode(moduleId)}#${accessId}` };
  }, {});

  return accessMap;
}

/**
 * 海鼎权限code权重 > 微盟的权限code
 * @param code 微盟权限code
 * @param headingCode  海鼎权限code
 * @returns 是否有某个权限
 */
export function hadAccess(code: string, headingCode = ''): boolean {
  const accessMap = hadHeadingAccess(headingCode) && window.kraken.invoke('checkAuth')(code);
  return accessMap;
}
