let headingAccess: string[] = [];

export function setHeadingAccess(access: string[]) {
  headingAccess = access;
}

/**
 *
 * @param code 海鼎权限code
 * @returns true有权限 false没有权限
 */
export function hadHeadingAccess(code: string): boolean {
  if (!code) return true;
  const access = headingAccess.find(access => access === code);
  return !!access;
}
