/* eslint-disable @typescript-eslint/ban-types */
import fetch from 'dva/fetch';
import { notification, message } from 'antd';
import { getQueryPath } from './utils';
import { apiUrl, posUrl, cardUrl,settingUrl,onlineOrderUrl,onlineGoodsUrl, VITE_FETCH_BASE_URL } from '@/config';
import { encode } from './cryptoUnreverse';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
};
const urlMap = {
  main: apiUrl,
  pos: posUrl,
  card: cardUrl,
  setting: settingUrl,
  onlineOrder: onlineOrderUrl,
  onlineGoods: onlineGoodsUrl
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  notification.error({
    message: `请求错误 ${response.status}: ${response.url}`,
    description: errortext
  });
  const error: any = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

const isSupplierBackend = () => {
  const reg = /\/supplier\-backend\//;
  return reg.test(window.location.pathname);
};

/**
 *  fetch 请求封装
 * @param {*} url
 * @param {*} method
 * @param {*} data
 * @param {*} timeout
 */
const request = (url, method = 'POST', data, options = {}, timeout = 1000 * 30, baseUrl = 'main') => {
  const _options = {
    isShowMsg: true,
    baseUrl: urlMap[baseUrl],
    ...options
  };

  // const token = window.kraken.getCache('saas-token');
  // const cachePidStore = window.kraken.getCache('keyData');
  const token = window.kraken.getCache('saas-token');
  const cachePidStore = window.kraken.getCache('keyData');
  // const iwmsSecret = window.kraken.getCache('iwms-secret');
  let loginId = window.kraken.getCache('userInfo').loginId ?? undefined;
  const opts: any = {
    method,
    timeout,
    credentials: 'include',
    headers: {
      'Content-Type':
        method == 'POST' ? 'application/json; charset=utf-8' : 'application/x-www-form-urlencoded;charset=utf-8',
      // Authorization: `Bearer ${token}`,
      // Apiclient: 'saas-pc',
      iwmsJwt: token,
      checkFlag : false
      // 'weimob-pid': cachePidStore && cachePidStore.bosId,
      // 'Access-Control-Allow-Origin': '*'
    }
  };
  // 自动添加用户pid,storeId
  if (cachePidStore) {
    const pid = cachePidStore.bosId;
    const storeId = cachePidStore.vid ?? undefined;
    const merchantId = cachePidStore.merchantId ?? undefined;
    const vType = cachePidStore.vidType ?? undefined;
    const originProductInstanceId = cachePidStore.productInstanceId ?? undefined;
    const supplierId = isSupplierBackend() ? cachePidStore.bosId : undefined;

    data = {
      pid,
      storeId,
      wid: window.kraken.getCache('userInfo')?.wid ?? undefined,
      supplierId,
      originProductInstanceId,
      vid: storeId,
      bosId: pid,
      vType,
      merchantId,
      ...data
    };
  }

  if (method == 'POST') {
    opts.body = JSON.stringify(data);
  } else if (method == 'GET') {
    data && url == getQueryPath(url, data);
  }
  let source = url.replace(VITE_FETCH_BASE_URL, "") + "&&" + loginId;
  if (opts.body) {
    source = '/cx-main/heading/cx/main'+ source + "@@" + opts.body;
  }
  let sercet = encode(source);
  opts.headers = {
    iwmsSecret: sercet,
    ...opts.headers,
  };
  const backArr = ['/api/pcweb/purchase/plan/getStoreSell', '/api/pcweb/purchase/plan/getIsTodayFinishPlan'];
  // return Promise.resolve({ data: [], errcode: '', errmsg: '', });
  return fetch(`${_options.baseUrl}${url}`, opts)
    .then(checkStatus)
    .then(response => {
      return response.json();
    })
    .then(response => {
      if (response && response.errcode == 1041) {
        // 跳出登录
        window.kraken.dispatchEvent('kraken:sessionInvalid');
      } else {
        if (response.errcode == 0 || response.errcode == '0') {
          return response;
        } else {
          if (backArr.includes(url) && response.errcode == '500') {
            return response;
          }
          if (_options.isShowMsg) {
            message.warning(response.errmsg);
          }

          return Promise.reject(response);
        }
      }
    });
  // .catch(e => {
  //   console.log('==================>>', e);
  // });
};

export default {
  get<T extends Object>(
    url: string,
    data?: any,
    opts?: any,
    baseUrl?: string
  ): Promise<{ data: T; errcode: string; errmsg: string }> {
    return request(url, 'GET', data, opts, 1000 * 30, baseUrl);
  },
  post<T extends Object>(
    url: string,
    data?: any,
    opts?: any,
    baseUrl?: string
  ): Promise<{ data: T; errcode: string; errmsg: string }> {
    return request(url, 'POST', data, opts, 1000 * 30, baseUrl);
  }
};
